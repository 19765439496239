import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

function VerticalLoder({ device }) {
  return (
    <>
    <Box sx={{ p: "6px 0px", backgroundColor: "#FAFAFA" }}>
      <Skeleton sx={{ my: 2 , height:"32px"}} />
    </Box>
      {device === "desktop" ? (
        <Grid
          container
          spacing={2}
          sx={{ display: { xs: "none", sm: "flex" } }}>
          <Grid item xs={12}>
            <Skeleton />
          </Grid>
          <Grid item xs={10}>
            <Skeleton />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%", ml: "auto" }} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%" }} />
          </Grid>
          <Grid item xs={10}>
            <Skeleton sx={{ ml: "auto" }} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%" }} />
          </Grid>
          <Grid item xs={10}>
            <Skeleton sx={{ ml: "auto" }} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%" }} />
          </Grid>
          <Grid item xs={10}>
            <Skeleton sx={{ ml: "auto" }} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%" }} />
          </Grid>
          <Grid item xs={10}>
            <Skeleton sx={{ ml: "auto" }} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%" }} />
          </Grid>
          <Grid item xs={10}>
            <Skeleton sx={{ ml: "auto" }} />
          </Grid>
          <Grid item xs={10}>
            <Skeleton />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%", ml: "auto" }} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%" }} />
          </Grid>
          <Grid item xs={10}>
            <Skeleton sx={{ ml: "auto" }} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%" }} />
          </Grid>
          <Grid item xs={10}>
            <Skeleton sx={{ ml: "auto" }} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%" }} />
          </Grid>
          <Grid item xs={10}>
            <Skeleton sx={{ ml: "auto" }} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%" }} />
          </Grid>
          <Grid item xs={10}>
            <Skeleton sx={{ ml: "auto" }} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton width={30} sx={{ borderRadius: "50%" }} />
          </Grid>
          <Grid item xs={10}>
            <Skeleton sx={{ ml: "auto" }} />
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
}

export default VerticalLoder;
